// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-airplane-js": () => import("./../../../src/pages/Airplane.js" /* webpackChunkName: "component---src-pages-airplane-js" */),
  "component---src-pages-aliens-js": () => import("./../../../src/pages/Aliens.js" /* webpackChunkName: "component---src-pages-aliens-js" */),
  "component---src-pages-all-i-can-see-js": () => import("./../../../src/pages/AllICanSee.js" /* webpackChunkName: "component---src-pages-all-i-can-see-js" */),
  "component---src-pages-bacteria-js": () => import("./../../../src/pages/Bacteria.js" /* webpackChunkName: "component---src-pages-bacteria-js" */),
  "component---src-pages-broken-js": () => import("./../../../src/pages/Broken.js" /* webpackChunkName: "component---src-pages-broken-js" */),
  "component---src-pages-concentration-js": () => import("./../../../src/pages/Concentration.js" /* webpackChunkName: "component---src-pages-concentration-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-deep-in-the-jungle-js": () => import("./../../../src/pages/DeepInTheJungle.js" /* webpackChunkName: "component---src-pages-deep-in-the-jungle-js" */),
  "component---src-pages-frustration-js": () => import("./../../../src/pages/Frustration.js" /* webpackChunkName: "component---src-pages-frustration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minimalism-js": () => import("./../../../src/pages/Minimalism.js" /* webpackChunkName: "component---src-pages-minimalism-js" */),
  "component---src-pages-robot-js": () => import("./../../../src/pages/Robot.js" /* webpackChunkName: "component---src-pages-robot-js" */),
  "component---src-pages-sanderstead-js": () => import("./../../../src/pages/Sanderstead.js" /* webpackChunkName: "component---src-pages-sanderstead-js" */),
  "component---src-pages-shock-js": () => import("./../../../src/pages/Shock.js" /* webpackChunkName: "component---src-pages-shock-js" */),
  "component---src-pages-walk-js": () => import("./../../../src/pages/Walk.js" /* webpackChunkName: "component---src-pages-walk-js" */),
  "component---src-pages-what-is-normal-js": () => import("./../../../src/pages/WhatIsNormal.js" /* webpackChunkName: "component---src-pages-what-is-normal-js" */),
  "component---src-pages-where-is-my-mind-js": () => import("./../../../src/pages/WhereIsMyMind.js" /* webpackChunkName: "component---src-pages-where-is-my-mind-js" */)
}

